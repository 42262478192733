@import url("./variables.scss");
@import url("./fonts.scss");

html {
  scroll-behavior: smooth;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}
.justify-between {
  justify-content: space-between;
}
.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}
.width-100 {
  width: 100%;
}
.display-none {
  display: none;
}
.section-header {
  text-align: center;
  width: 100%;
  font-size: 35px;
  font-weight: 600;
  color: var(--blue-primary);
}

::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  background-color: #f5f5f5; /* Background color of the scrollbar track */
}

/* Customize the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #3ad77e9c; /* Color of the scrollbar thumb */
}

/* Customize the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #3ad77ec2;
  /* Color of the scrollbar thumb on hover */
}
