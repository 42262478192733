.about-container {
  overflow-x: clip;
  .vision-mission-container {
    padding: 40px 0px;
    .row {
      .card {
        width: 45%;
        .image {
          height: 80px;
          width: 80px;
        }
        .header {
          color: var(--blue-primary);
          font-size: 25px;
          font-weight: 600;
          margin: 20px 0px;
        }
        .content {
          font-size: 17px;
          line-height: 35px;
          color: var(--darkest-green);
        }
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        .card {
          width: 90%;
          margin: 30px 0px;
        }
      }
    }
  }
}
