.about-container {
  margin-top: 130px;

  .background-vector-1,
  .background-vector-2 {
    position: absolute;
    width: 120px;
    object-fit: contain;
    z-index: -1;
  }
  .background-vector-1 {
    transform: rotate(180deg);
    margin-top: -500px;
    left: 0;
  }
  .background-vector-2 {
    margin-top: -400px;
    width: 160px;
    right: 0;
  }
}
