.footer-container {
  padding: 50px 20px;
  border-top: 1px solid var(--black-20);
  background-color: var(--light-background);

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .footer-left {
      flex: 1 1 30%;
      margin-bottom: 20px;

      .footer-logo {
        width: 150px;
        margin-bottom: 15px;
      }

      .footer-description {
        color: var(--dimmed);
        font-size: 15px;
        line-height: 1.6;
      }
    }

    .footer-links {
      flex: 1 1 70%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      color: var(--dimmed);

      .footer-header {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px;
        color: var(--blue-primary);
      }

      .footer-link {
        text-decoration: none;
        margin-bottom: 10px;

        text {
          font-size: 14px;
          color: var(--blue-primary);
          transition: color 0.3s;

          &:hover {
            color: var(--dark-blue);
          }
        }
      }
    }
  }

  .footer-bottom {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid var(--black-20);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .footer-copy {
      font-size: 14px;
      color: var(--dimmed);
    }

    .footer-socials {
      display: flex;
      gap: 10px;

      .social-link {
        color: var(--blue-primary);

        transition: transform 0.3s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .footer {
      flex-direction: column;

      .footer-left {
        margin-bottom: 30px;
      }
    }

    .footer-bottom {
      flex-direction: column;
      gap: 10px;

      .footer-socials {
        justify-content: center;
      }
    }
  }
}
