.home-container {
  .blog-container {
    padding: 40px 0px 120px 0px;
    .header-row {
      .header {
        width: fit-content;
        margin-right: 20px;
        margin-left: 120px;
      }
    }
    .posts {
      .indicators {
        margin-bottom: -70px;
        .indicator {
          border: 1px solid var(--green);
          height: 22px;
          width: 22px;
          transition-duration: 0.86s;
          &[data-active] {
            transform: scale(1);
            background-color: var(--green);
          }
        }
      }
      .post {
        width: 300px;
        height: 380px;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 20px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba($color: #000000, $alpha: 0.3);
        .tag {
          color: var(--dimmer-secondary);
        }
        .title {
          font-size: 34px;
          font-weight: 600;
          color: var(--white);
        }
      }
    }
    @media screen and (max-width: 700px) {
      .header-row {
        .header {
          margin-left: 0px;
        }
      }
    }
  }
}
