.blogs-container {
  margin-top: 130px;
  padding-bottom: 40px;

  .top {
    margin-bottom: 100px;
    .featured-post {
      width: 55%;
      height: 500px;
      border-radius: 10px;
      text-decoration: none !important;
      box-sizing: border-box;
      padding: 30px;
      background-color: rgba($color: #000000, $alpha: 0.2);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      .content {
        text-decoration: none !important;
        .tag {
          text-decoration: none !important;
          border: 1px solid var(--white);
          padding: 4px 12px;
          width: fit-content;
          border-radius: 3px;
          font-size: 17px;
          color: var(--white);
          font-weight: 500;
        }
        .title {
          text-decoration: none !important;
          font-size: 30px;
          text-transform: capitalize;
          font-weight: 600;
          color: var(--white);
        }
      }
    }
    .recent-stories {
      width: 40%;
      .header-row {
        margin-bottom: 20px;
        .header {
          color: var(--green);
          font-size: 18px;
          font-weight: 600;
        }
        .divider {
          width: calc(100% - 160px);
        }
      }
      .stories {
        padding: 20px 10px;
        box-sizing: border-box;
        .story {
          height: 100px;
          margin: 10px 0px;
          box-sizing: border-box;
          text-decoration: none !important;
          .left {
            .title {
              color: var(--blue-primary);
              font-weight: 600;
              font-size: 17.5px;
              text-decoration: none !important;
            }
            .tag {
              color: var(--blue-primary);
              padding: 3px 7px;
              width: fit-content;
              border-radius: 4px;
              border: 1px solid var(--blue-primary);
              font-size: 14px;
              text-decoration: none !important;
              font-weight: 500;
              margin-top: 5px;
            }
          }
          .thumbnail {
            width: 100px;
            object-fit: contain;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .posts {
    .indicators {
      margin-bottom: -70px;
      .indicator {
        border: 1px solid var(--green);
        height: 20px;
        width: 20px;
        transition-duration: 0.86s;
        &[data-active] {
          transform: scale(1.2);
          background-color: var(--green);
        }
      }
    }
    .post {
      margin: 10px;
      height: 380px;
      border-radius: 5px;
      flex-direction: column !important;
      box-sizing: border-box;
      padding: 20px;
      box-sizing: border-box;
      background-size: cover;
      background-color: rgba($color: #000000, $alpha: 0.2);
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      transition-duration: 0.12s;
      text-decoration: none !important;
      &:hover {
        transform: scale(1.05);
      }
      .tag {
        color: var(--white);
      }
      .title {
        font-size: 32px;
        font-weight: 600;
        color: var(--white);
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .top {
      flex-direction: column;
      .featured-post {
        width: 100%;
      }
      .recent-stories {
        width: 100%;
        margin-top: 50px;
        .stories {
          .story {
            .left {
              .title {
                font-size: 20px;
              }
              .tag {
                font-size: 16px;
              }
            }
            .thumbnail {
              height: unset;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 650px) {
    .top {
      .recent-stories {
        width: 100%;
        margin-top: 50px;
        .stories {
          .story {
            height: 120px;
            .left {
              .title {
                font-size: 17px;
              }
              .tag {
                font-size: 13px;
              }
            }
            .thumbnail {
              height: unset;
            }
          }
        }
      }
    }
  }
}
