.about-container {
  .values-container {
    padding: 40px 0px;
    .values {
      margin-top: 30px;
      .value {
        padding: 20px 0px 0px 10px;
        width: 400px;
        height: 200px;
        border: 1px solid var(--darkest-green);
        border-radius: 5px;
        .title {
          color: var(--darkest-green);
          font-size: 27px;
          font-weight: 600;
        }
        .content {
          max-width: 95%;
          font-size: 14.5px;
          line-height: 30px;
          color: var(--darkest-green);
        }
      }
    }
    @media screen and (max-width: 470px) {
      .values {
        .value {
          width: 98%;
          height: fit-content;
          padding-bottom: 24px;
        }
      }
    }
  }
}
