.home-container {
  // margin-top: 130px;
  overflow-x: clip;
  .background-vector-1,
  .background-vector-2 {
    position: absolute;
    width: 120px;
    object-fit: contain;
    z-index: -1;
  }
  .background-vector-1 {
    top: 150vh;
    transform: rotate(180deg);
    left: 0;
  }
  .background-vector-2 {
    margin-top: -500px;
    width: 160px;
    right: 0;
  }
}
