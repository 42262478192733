.articles-container {
  .articles {
    padding: 20px 0px 50px 0px;
    .article {
      margin: 40px 0px;
      .image {
        width: 340px;
        border-radius: 15px;
        object-fit: contain;
        height: fit-content;
      }
      .content {
        margin-left: 30px;
        .title {
          color: var(--darkest-green);
          font-weight: 600;
          font-size: 30px;
        }
        .dimmed {
          font-size: 20px;
          color: var(--dimmed);
          font-weight: 500;
          font-style: italic;
        }
        .description {
          line-height: 35px;
          font-size: 19px;
        }
      }
    }
    .article-reversed {
      flex-direction: row-reverse;
      .content {
        margin-left: 0px;
        margin-right: 30px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    // THIS STYLING IS DUE TO FARMER-COMMENTS BEING AN UNRESPONSIVE AHH COMPONENT
    margin-top: 180px;
  }
  @media screen and (max-width: 800px) {
    .articles {
      .article,
      .article-reversed {
        flex-direction: column !important;
        margin: 10px !important;
        .content {
          margin: 20px 0px !important;
        }
      }
    }
  }
  @media screen and (max-width: 550px) {
    .articles {
      .article {
        .content {
          .title {
            font-size: 26px;
          }
          .dimmed {
            font-size: 18px;
            margin: 5px 0px;
          }
          .description {
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    .articles {
      .article {
        .image {
          width: 300px;
        }
      }
    }
  }
}
