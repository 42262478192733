.farmers-container {
  .comments-container {
    overflow: clip;
    margin-top: 100vh;
    padding: 50px 0px;
    .comments {
      .left {
        width: 350px;
        border-radius: 20px;
        height: 450px;
      }
      .right {
        width: calc(100% - 500px);
        .header {
          font-size: 28px;
          font-weight: 700;
          color: var(--green);
        }
        .avatars {
          width: 100%;
          margin-left: 35px;
          margin-top: 22px;
          .avatar {
            height: 140px;
            width: 140px;
            border-radius: 50%;
            .image {
              height: 122px;
              border-radius: 50%;
              width: 122px;
            }
            margin-left: -35px;
          }
          .avatar-green {
            background-color: var(--green);
          }
          .avatar-darkest-green {
            background-color: var(--darkest-green);
          }
          .avatar-blue {
            background-color: var(--blue-primary);
          }
        }
        .comment {
          font-size: 22px;
          line-height: 37px;
          font-weight: 500;
          margin: 20px 0px;
          max-width: 700px;
          color: var(--darkest-green);
          position: absolute;
          top: calc(100vh + 260px);
        }
      }
    }
    .story-header {
      color: var(--green);
      font-size: 23px;
      font-weight: 600;
      margin: 70px 0px 40px 0px;
    }
    .story-card {
      background: var(--white);
      box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      padding: 20px 40px 20px 20px;
      box-sizing: border-box;
      height: 500px;
      justify-content: space-between;
      .small-logo {
        height: 60px;
        width: 50px;
      }
      .content {
        margin-left: 100px;
        .left {
          .message-container {
            position: absolute;
            width: 600px;
            height: 140px;
            .message {
              font-size: 19px;
              line-height: 35px;
              font-weight: 500;
              color: var(--darkest-green);
            }
          }
          .name {
            font-size: 35px;
            font-weight: 700;
            color: var(--green);
            margin-top: 180px;
          }
          .label,
          .organization {
            color: var(--dimmed);
            font-size: 16px;
            font-weight: 500;
          }
          .organization {
            font-weight: 600;
          }
        }
        .right {
          .image {
            height: 120px;
            width: 120px;
          }
        }
      }
      .full-logo {
        height: 50px;
      }
    }
    .indicators-container {
      .indicators {
        margin: 30px 0px;
        .indicator {
          border: 1px solid var(--green);
          height: 22px;
          width: 22px;
          border-radius: 50%;
          margin: 0px 3px;
          transition-duration: 0.12s;
          background-color: var(--white);
        }
        .indicator-active {
          height: 26px;
          width: 26px;
          background-color: var(--green);
          box-shadow: 0px 0px 7px -2px var(--green);
        }
        // .indicator-inactive {
        // }
      }
    }
    @media screen and (max-width: 1120px) {
      .comments {
        .right {
          .avatars {
            width: fit-content;
            .avatar {
              height: 100px;
              width: 100px;
              .image {
                height: 90px;
                width: 90px;
              }
            }
          }
          .comment {
            font-size: 20px;
            max-width: 50%;
            top: calc(100vh + 220px);
          }
        }
      }
    }
    @media screen and (max-width: 1000px) {
      .comments {
        .left {
          display: none;
        }
        .right {
          width: 100%;
          .avatars {
            margin-top: 30px;
            margin-left: 65px;
            .avatar {
              height: 220px;
              width: 220px;
              margin-left: -65px;
              .image {
                height: 200px;
                width: 200px;
              }
            }
          }
          .comment {
            max-width: unset;
            width: 90%;
            top: calc(100vh + 370px);
          }
        }
      }
      .story-header {
        margin-top: 150px;
      }
    }
    @media screen and (max-width: 950px) {
      .story-card {
        .content {
          .left {
            .message-container {
              max-width: 70%;
              width: unset;
            }
          }
        }
      }
    }
    @media screen and (max-width: 900px) {
      .comments {
        .right {
          .avatars {
            margin-top: 30px;
            margin-left: 20px;
            width: 100%;
            .avatar {
              height: unset;
              width: 22%;
              aspect-ratio: 1/1;

              margin-left: -20px;
              .image {
                height: 90%;
                width: 90%;
              }
            }
          }
          .comment {
            top: calc(100vh + +320px);
          }
        }
      }
      .story-header {
        margin-top: 200px;
      }
    }
    @media screen and (max-width: 850px) {
      .story-card {
        .content {
          .left {
            .message-container {
              max-width: 70%;
              .message {
                font-size: 20px;
              }
            }
            .name {
              font-size: 30px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 800px) {
      .comments {
        .right {
          .comment {
            top: calc(100vh + +300px);
          }
        }
      }
      .story-header {
        margin-top: 180px;
      }
      .story-card {
        .content {
          .left {
            .name {
              margin-top: 200px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 750px) {
      .comments {
        .right {
          .comment {
            top: calc(100vh + +260px);
            font-size: 17px;
            line-height: 32px;
          }
        }
      }
      .story-header {
        margin-top: 300px;
      }
    }
    @media screen and (max-width: 700px) {
      .comments {
        .right {
          .comment {
            top: calc(100vh + +260px);
          }
        }
      }
    }

    @media screen and (max-width: 700px) {
      .story-card {
        height: 550px;
        .logo-row {
          justify-content: flex-end;
        }
        .content {
          flex-direction: column-reverse;
          margin-top: -60px;
          margin-left: 0px;
          .left {
            .name {
              margin-top: 260px;
              font-size: 20px;
            }
            .message-container {
              width: 95%;
              max-width: unset !important;
              margin-top: 30px;
              .message {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 500px) {
      .comments {
        .right {
          .comment {
            top: calc(100vh + +240px);
          }
        }
      }
      .story-header {
        margin-top: 280px;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .story-card {
    height: 650px !important;
    .content {
      .left {
        .name {
          margin-top: 320px !important;
          font-size: 19px;
        }
        .message-container {
          width: calc(100vw * 0.75) !important;
          .message {
            font-size: 15px;
            line-height: 30px !important;
          }
        }
      }
    }
    .full-logo {
      margin-top: 30px;
    }
  }
}
