.about-container {
  .team-container {
    padding: 10px 0px 40px 0px;
    .team {
      padding: 30px 0px;
      .member {
        width: 200px;
        height: 270px;
        box-sizing: border-box;
        cursor: pointer;
        transition-duration: 0.12s;
        .content {
          padding: 0px 0px 10px 10px;
          box-sizing: border-box;
          position: absolute;
          z-index: 999999;

          .position {
            color: var(--white);
            font-weight: 700;
            font-size: 13px;
          }
          .name {
            font-size: 25px;
            color: var(--white);
            font-weight: 600;
          }
        }
        &:hover {
          transform: scale(1.03);
        }
      }
      .member-green {
        background-color: var(--green);
      }
      .member-blue {
        background-color: var(--blue-primary);
      }
      .member-bg,
      .member-overlay {
        background-size: cover;
        width: 200px;
        height: 270px;
        position: absolute;
      }
      .member-bg {
        z-index: 9;
      }
      .member-overlay {
        z-index: 99;
      }
      .member-overlay {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.088) 29%,
          rgba(0, 0, 0, 0.22) 53%
        );
      }
    }
    @media screen and (max-width: 670px) {
      .team {
        .member {
          width: 180px;
          height: 250px;
          .content {
            .position {
              font-size: 12px;
            }
            .name {
              font-size: 23px;
            }
          }
        }
        .member-bg,
        .member-overlay {
          width: 180px;
          height: 250px;
        }
      }
    }
    @media screen and (max-width: 570px) {
      .team {
        .member {
          width: 180px;
          height: 200px;
        }
        .member-bg,
        .member-overlay {
          width: 180px;
          height: 200px;
        }
      }
    }
    @media screen and (max-width: 450px) {
      .team {
        .member {
          width: 160px;
          height: 190px;
          .content {
            .name {
              height: 40px;
              font-size: 20px;
            }
          }
        }
        .member-bg,
        .member-overlay {
          width: 160px;
          height: 190px;
        }
      }
    }
  }
}
.team-modal {
  .profile {
    .top {
      height: 270px;
      background-image: url("../../../Assets/IMG/GradientBG.png");
      padding: 20px 40px;
      .image {
        height: 250px;
      }
    }
    .content {
      padding: 20px 40px;
      .name {
        color: var(--blue-primary);
        font-size: 26px;
        font-weight: 600;
      }
      .link {
        width: fit-content;
      }
      .bio {
        line-height: 30px;
        font-size: 14.5px;
        margin: 20px 0px;
      }
    }
  }
}
