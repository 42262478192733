.home-container {
  .services-container {
    padding: 0px 0px;
    .service {
      flex: 1 1 calc(25% - 1.2rem); // Four cards per row
      width: 20rem; // Ensure full width for each flex item

      background: #fff; // Card background
      border-radius: 12px; // Rounded corners
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); // Subtle shadow
      padding: 1.5rem;
      text-align: center;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:hover {
        transform: translateY(-5px); // Lift effect on hover
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); // Stronger shadow on hover
      }
      height: 440px;
      .number {
        color: var(--dimmed);
        font-size: 12px;
        margin-bottom: 30px;
      }
      .image {
        height: 75px;
        width: 75px;
      }
      .title {
        font-size: 19px;
        font-weight: 600;
        margin: 10px 0;
      }
      .description {
        font-size: 14px;
        line-height: 30px;
        height: 200px;
      }
      .btn {
        width: 200px;
      }
    }
    @media screen and (max-width: 620px) {
      .service {
        width: 100%;

        margin: 20px 0px;
      }
    }
  }
}
