.farmers-container {
  .montage-container {
    .montage {
      height: 100vh;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)),
        url("../../../Assets/IMG/Farmers/farmer.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
}
