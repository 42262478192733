@keyframes MouseAnimation {
  0% {
    margin-bottom: 0px;
  }
  50% {
    margin-bottom: -40px;
  }
  100% {
    margin-bottom: 0px;
  }
}

.transform-container {
  .jumbo-container {
    height: 100vh;
    .jumbotron {
      background-color: var(--black-20);
      box-sizing: border-box;
      padding-bottom: 35vh;
      height: 100%;
      .header {
        color: var(--white);
        font-size: 70px;
        font-weight: 700;
        text-align: center;
        padding: 0 20px;
      }
      .mouse-image {
        position: absolute;
        bottom: 20vh;
        cursor: pointer;
        animation: MouseAnimation 2s forwards infinite;
      }
    }
    .jumbo-video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
      top: 0;
      left: 0;
    }
    @media screen and (max-width: 600px) {
      .jumbotron {
        .header {
          font-size: 50px;
        }
      }
    }
  }
}
