.stats-container {
  padding: 60px 0;

  .section-header {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 30px;
    color: var(--dark-green);
  }

  .stat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 160px;
    padding: 20px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    }

    .stat-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .stat-value {
        font-size: 28px;
        font-weight: 700;
        color: var(--green);
      }

      .stat-icon {
        height: 60px;
        width: 60px;
      }
    }

    .stat-label {
      margin-top: 15px;
      font-size: 18px;
      font-weight: 600;
      color: var(--darkest-green);
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    .stat {
      height: 180px;

      .stat-top {
        .stat-icon {
          height: 70px;
          width: 70px;
        }

        .stat-value {
          font-size: 30px;
        }
      }

      .stat-label {
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .stat {
      height: 220px;

      .stat-top {
        flex-direction: column;
        align-items: center;

        .stat-value {
          font-size: 28px;
          margin-top: 10px;
        }

        .stat-icon {
          margin-top: 15px;
        }
      }

      .stat-label {
        font-size: 16px;
        margin-top: 10px;
      }
    }
  }
}
