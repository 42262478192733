.carousel-container {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: 100%;
}

.prev-button,
.next-button {
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.indicators {
  position: absolute;
  display: flex;
  gap: 0.5rem;
}
.indicator {
  width: 0.75rem;
  height: 0.75rem;
  transition: background-color 0.3s;
}
