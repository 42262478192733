.service-container {
  overflow-x: clip;
  margin-top: 130px;
  .background-vector-1,
  .background-vector-2 {
    position: absolute;
    width: 120px;
    object-fit: contain;
    z-index: -1;
  }
  .background-vector-1 {
    transform: rotate(180deg);
    margin-top: 500px;
    left: 0;
  }
  .background-vector-2 {
    margin-top: -400px;
    width: 160px;
    right: 0;
  }
  .jumbotron {
    .left {
      .hero {
        width: 400px;
        margin-bottom: 30px;
      }
    }
    .right {
      max-width: 700px;
      .name {
        color: var(--blue-primary);
        font-weight: 700;
        text-transform: uppercase;
        font-size: 40px;
        padding: 0px 20px;
      }
      .benefit {
        width: 320px;
        box-sizing: border-box;
        padding: 20px;
        .image {
          width: 75px;
        }
        .title {
          font-size: 19px;
          color: var(--blue-primary);
          font-weight: 600;
          margin: 10px 0px;
        }
        .content {
          line-height: 30px;
        }
      }
    }
  }
  .title {
    font-weight: 600;
    font-size: 30px;
    color: var(--blue-primary);
    margin-top: 50px;
  }
  .paragraph {
    margin: 20px 0px;
    font-size: 19px;
    line-height: 40px;
  }
  .features {
    padding: 80px 0px;
    .feature {
      width: 30%;
      height: 500px;
      border-radius: 20px;
      padding: 20px 0px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      .title {
        color: var(--white);
        padding: 20px 0px 20px 20px;
        font-weight: 600;
      }
      .dimmed {
        color: var(--white);
        font-size: 14px;
        padding: 20px 0px 0px 20px;
      }
    }
  }
  .section-header {
    margin-top: 60px;
  }
  .form {
    padding: 40px 0px 80px 0px;
    .row {
      margin-bottom: 20px;
      .input {
        width: 48%;
      }
      .text-input {
        height: 46px;
      }
    }
    .textarea {
      height: 160px;
      margin-bottom: 20px;
    }
  }
  .tabs-container {
    width: 100%;
    position: fixed;
    z-index: 999999;
    left: 0px;
    bottom: 0px;
    .tabs {
      width: 100%;
      max-width: 800px;
      border: var(--dimmer-dark);
      background-color: var(--dimmer-tertiary);
      height: 60px;
      padding: 0px 20px;
    }
    .small-tabs {
      display: none;
      width: 400px;
      background-color: var(--dimmer-tertiary);
      height: 60px;
      padding: 0px 20px;
    }
  }
  @media screen and (max-width: 1120px) {
    .jumbotron {
      .right {
        .benefit {
          width: 300px;
        }
      }
    }
    .features {
      .feature {
        height: 400px;
        .title {
          font-size: 25px;
        }
        .dimmed {
          height: 70px;
        }
      }
    }
  }
  @media screen and (max-width: 1070px) {
    .jumbotron {
      flex-direction: column;
      .left {
        position: absolute;
        height: 350px;
        right: 40px;
        .hero {
          width: unset;
          object-fit: contain;

          height: 350px;
        }
      }
      .right {
        max-width: calc(100% - 350px);
        margin: 0px;
      }
    }
    .features {
      .feature {
        height: 350px;
        width: 260px;
        .dimmed {
          height: 90px;
          padding: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 860px) {
    .features {
      .feature {
        width: 32%;
        height: 330px;
        .title {
          padding: 10px;
        }
        .dimmed {
          height: 80px;
          padding: 10px;
        }
      }
    }
    .tabs-container {
      max-width: unset;
    }
  }
  @media screen and (max-width: 700px) {
    .jumbotron {
      .left {
        display: none;
      }
      .right {
        max-width: unset;
        width: 100%;
        .name {
          padding: 20px 0px;
        }
        .benefit {
          width: 100%;
          padding: 20px 0px;
          .image {
            height: 50px;
            width: 50px;
          }
        }
      }
    }
    .title {
      font-size: 27px;
      margin: 0px;
    }
    .paragraph {
      font-size: 17px;
    }
    .features {
      .feature {
        height: 250px;
        .title {
          font-size: 20px;
        }
        .dimmed {
          height: 100px;
        }
      }
    }
    .tabs-container {
      .tabs {
        display: none;
      }
      .small-tabs {
        display: flex;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .features {
      flex-direction: column;
      .feature {
        width: 80%;
        height: 300px;
        background-position: top;
        margin: 10px 0px;
        .dimmed {
          height: fit-content;
        }
      }
    }
    .form {
      .row {
        flex-direction: column;
        .input {
          width: 100%;
          &:last-child {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
