.transform-container {
  .main-container {
    .transformations {
      margin-top: 120px;
      .transformation {
        margin-bottom: 120px;
        .image {
          width: 50%;
          height: 400px;
          object-fit: cover;
          background-position: bottom;
          object-position: bottom;
        }
        .content {
          width: 45%;
          .subtitle {
            color: var(--green);
          }
          .header {
            font-weight: 700;
            font-size: 35px;
            margin: 10px 0px;
            color: var(--blue-primary);
          }
          .description {
            font-size: 15px;
            line-height: 35px;
          }
          .btn {
            background-image: url("../../../Assets/IMG/GradientBG.png");
            background-position: 80%;
            width: 200px;
            height: 45px;
            margin-top: 20px;
          }
        }
      }
    }
    @media screen and (max-width: 850px) {
      .transformations {
        align-items: center;
        .transformation {
          margin-bottom: 20px;
          width: -webkit-fill-available;
          flex-direction: column;
          .image {
            margin-bottom: 30px;
            width: 100%;
          }
          .content {
            width: 90%;
          }
        }
        .image-last-transformation {
          flex-direction: column-reverse;
        }
      }
    }
  }
}
