.home-container {
  .newsletter-container {
    padding: 40px 0px 80px 0px;
    .newsletter {
      .image {
        width: 300px;
      }
      .right {
        min-height: 200px;
        margin-left: 30px;
        .header {
          width: fit-content;
          text-align: left;
        }
        .dimmed {
          color: var(--dimmed);
          font-size: 14px;
        }
      }
    }
    @media screen and (max-width: 882px) {
      .newsletter {
        .right {
          height: 250px;
        }
      }
    }
    @media screen and (max-width: 700px) {
      .newsletter {
        flex-direction: column;
        .image {
          width: 340px;
        }
        .right {
          height: 250px;
          margin: 20px 0 !important;
          .header {
            text-align: center;
          }
        }
      }
    }
    @media screen and (max-width: 430px) {
      .newsletter {
        .image {
          width: 310px;
        }
        .right {
          height: 280px;
        }
      }
    }
  }
}
