.jumbotron-container {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .jumbo-bg {
    position: absolute;
    inset: 0;
    background: url("../../../Assets/IMG/GradientBG.svg") no-repeat center;
    background-size: cover;
    z-index: -1;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    gap: 2rem;
    max-width: 1200px;
    width: 100%;

    @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 4rem;
    }

    .text-content {
      max-width: 500px;
      text-align: center;

      @media (min-width: 1024px) {
        text-align: left;
      }

      .header {
        font-size: 2.5rem;
        font-weight: bold;
        color: #1e3a8a;
        line-height: 1.2;

        @media (min-width: 768px) {
          font-size: 3.5rem;
        }

        @media (min-width: 1024px) {
          font-size: 4.5rem;
        }
      }

      .subtitle {
        font-size: 1rem;
        color: #065f46;
        margin-top: 1rem;

        @media (min-width: 768px) {
          font-size: 1.5rem;
        }
      }

      .buttons {
        margin-top: 2rem;
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        .btn-primary,
        .btn-secondary {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.75rem 1.5rem;
          font-size: 1rem;
          font-weight: bold;
          border-radius: 0.375rem;
          transition: all 0.3s;

          @media (min-width: 768px) {
            padding: 1rem 2rem;
            font-size: 1.125rem;
          }
        }

        .btn-primary {
          background-color: #1e3a8a;
          color: white;

          &:hover {
            background-color: #1d4ed8;
          }
        }

        .btn-secondary {
          border: 2px solid #065f46;
          color: #065f46;
          gap: 0.5rem;

          &:hover {
            background-color: #065f46;
            color: white;
          }

          .arrow-icon {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }

    .carousel-container {
      position: relative;
      width: 100%;
      max-width: 500px;
      height: 400px;
      overflow: hidden;

      @media (max-width: 768px) {
        height: 300px;
      }

      .carousel-image {
        position: absolute;
        inset: 0;
        background-size: cover;
        background-position: center;
        transition: opacity 0.5s ease-in-out;
      }

      .indicators {
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        gap: 0.5rem;

        .indicator {
          width: 0.75rem;
          height: 0.75rem;
          border: 2px solid #065f46;
          border-radius: 50%;
          background-color: transparent;
          transition: all 0.3s;

          &.active {
            background-color: #065f46;
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
